import { toCamelCase } from '@/utils/string/string';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { IconType } from 'react-icons';

type Status = {
  label: string;
  color: string;
  icon: IconType;
};

type StatusLabelProps = {
  status: Status;
  justifyContent?: string;
  testId?: string;
  minWidth?: string;
};

const StatusLabel: FC<StatusLabelProps> = (props: StatusLabelProps) => {
  const {
    status: { label, color, icon },
    testId,
    justifyContent = 'flex-end',
    minWidth = '72px',
  } = props;

  return (
    <Flex minW={minWidth} mx='2' justifyContent={justifyContent} alignItems='center'>
      <Icon as={icon} fontSize='sm' mr='5px' color={color} />
      <Text data-testid={testId} color={color}>
        {toCamelCase(label)}
      </Text>
    </Flex>
  );
};

export default StatusLabel;

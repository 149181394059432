import StatusLabel from '@/common/components/StatusLabel';
import type { WorkOrderStatus } from '@/graphql/types';
import type { FC } from 'react';
import { useWorkOrderStatus } from '../hooks/useWorkOrderStatus';

type WorkOrderStatusLabelProps = {
  status: WorkOrderStatus;
  justifyContent?: string;
  minWidth?: string;
};

const WorkOrderStatusLabel: FC<WorkOrderStatusLabelProps> = (props: WorkOrderStatusLabelProps) => {
  const { status, justifyContent, minWidth } = props;

  const { getStatusAppearanceInfo } = useWorkOrderStatus();

  const appearanceInfo = getStatusAppearanceInfo(status);

  return (
    <StatusLabel status={appearanceInfo} justifyContent={justifyContent} minWidth={minWidth} />
  );
};

export default WorkOrderStatusLabel;
